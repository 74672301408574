<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: Junio 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="contenido">
    <!-- Descripción -->
    <div class="d-flex align-center mb-3">
      <v-icon color="blue darken-4" size="55">library_books</v-icon>
      <span class="ms-4 descripcion">
        Herramienta diseñada para generar reportes en formato CSV 
        <br> de los paquetes entregados por la transportadora en un periodo de hasta un mes.
      </span>
    </div>
    <v-divider color="#9E9E9" class="mb-5"></v-divider>

    <!-- Filtros para buscar un reporte de tracting domicilio-->
    <section>
      <v-row>
        <!-- Campo para filtrar por fecha inicial -->
        <v-col cols="12" sm="6" md="4">
          <v-menu ref="menuFechaInicio" v-model="menuFechaInicio" transition="scale-transition" min-width="290px"
            :close-on-content-click="false" :return-value.sync="fechaInicialCalendario" :nudge-top="25" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="fechaInicialCalendario" label="Fecha inicial *" v-bind="attrs" v-on="on"
                outlined dense readonly :rules="rules.agregarRules">
              </v-text-field>
            </template>
            <v-date-picker v-model="fechaInicialCalendario" :first-day-of-week="1" :max="currentDate" locale="es-co" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="cleanFechaInicial">Borrar</v-btn>
              <v-btn text color="primary" @click="saveFechaInicial">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <!-- Campo para filtrar por fecha final -->
        <v-col cols="12" sm="6" md="4">
          <v-menu ref="menuFechaFinal" v-model="menuFechaFinal" transition="scale-transition" min-width="290px"
            :close-on-content-click="false" :return-value.sync="fechaFinalCalendario" :nudge-top="25" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="fechaFinalCalendario" label="Fecha final *" v-bind="attrs" v-on="on" 
                outlined dense readonly :error-messages="rangoFechas" :rules="rules.agregarRules">
              </v-text-field>
            </template>
            <v-date-picker v-model="fechaFinalCalendario" :first-day-of-week="1" :max="currentDate" locale="es-co" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="cleanFechaFinal">Borrar</v-btn>
              <v-btn text color="primary" @click="saveFechaFinal">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>

        <!-- campo para buscar y filtrar por bodega -->
        <v-col cols="12" sm="6" md="4">
          <v-text-field v-model="filtro.bodega" outlined dense readonly label="Bodega" @mousedown.prevent="dialogoBodegas = true" :clearable="true" @click:clear="borrarBodegas()"></v-text-field>
        </v-col>
          
      </v-row>

      <!-- Botón de generar reporte -->
      <v-sheet class="d-flex align-center justify-center" :color="disableReport ? 'grey lighten-4' : 'green lighten-5'" height="65px" width="100%" rounded>
        <v-btn v-if="inProgress === false" color="success" @click="listarTrackingDomicilio()" :disabled="disableReport">
          <v-icon>download</v-icon>
          generar reporte
        </v-btn>
        <div v-else class="d-flex justify-center">
          <v-icon class="rotate-animation" color="#0d47a1" large>rotate_right</v-icon>
          <span class="d-flex align-center font-weight-black primary--text ms-2">CARGANDO</span>
        </div>
      </v-sheet>
    </section>

    <!-- Alerta cuando no se encuentran resultados -->
    <v-alert class="mt-5" icon="error" color="error" transition="scale-transition" :value="alert" outlined>
      <h3>No se encontraron resultados.</h3>
      <p class="no-resultados">Los datos ingresados no coinciden con ningún registro.
      <br>Verifica que los filtros sean correctos o intenta una búsqueda diferente.</p>
    </v-alert>

    <v-dialog v-model="dialogoBodegas" transition="dialog-bottom-transition" max-width="40rem" persistent>
      <v-card>
        <v-card-title class="d-flex justify-center">
          <span>Bodegas</span>
        </v-card-title>
        <v-card-text>
          <div class="filtrosBodega">
            <v-text-field class="me-2" outlined dense hide-details label="Código" v-model="buscarCodigoBodega"></v-text-field>
            <v-text-field outlined dense hide-details label="Nombre" v-model="buscarNombreBodega"></v-text-field>
          </div>
          
          <v-data-table :loading="loading" fixed-header :headers="headersBodegas" :items="listaDeBodegas"
            :page.sync="pagina" :items-per-page.sync="itemsPorPagina" :server-items-length="totalPaginas"
            class="mt-2 estiloFilas" height="32vh" :footer-props="footerPropsTabla" :single-select="singleSelect" show-select>
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="item in items" :key="item.id">
                  <td>
                    <v-checkbox :value="item" v-model="bodegaSeleccionada" :input-value="isSelected(item.codigoBodega)" @change="selectBodega(item)"></v-checkbox>
                  </td>
                  <td>{{ item.codigoBodega }}</td>
                  <td>{{ item.nombreBodega }}</td>
                </tr>
              </tbody>
            </template>
            <template v-slot:footer.page-text="items">
              {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
            </template>
          </v-data-table>

          <div v-if="bodegasSeleccionadas.length > 0" class="mt-2 mb-3" style="overflow-y: auto; max-height: 20vh;" >
            <v-chip-group column>
              <v-chip v-for="codigo in bodegasSeleccionadas" :key="codigo" color="primary" text-color="white" close @click:close="remove(codigo)">{{ codigo }}</v-chip>
            </v-chip-group>
          </div>

          <div class="d-flex justify-end">
            <v-btn class="me-2" text color="red" @click="cerrarDialogoBodegas()">CANCELAR</v-btn>
            <v-btn color="success" :disabled="bodegasSeleccionadas.length < 1" @click="confirmarBodegas()">CONFIRMAR</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>
 <!-- #################################################################################### -->
 <!-- ###### Sección de Scripts                                                     ###### -->
 <!-- #################################################################################### -->
<script>
import { mapState } from "vuex";
import { Role } from "@/router/role.js";
import Papa from 'papaparse'

/**
 * Esta función toma una matriz de matrices (una matriz bidimensional) 
 * donde cada sub-matriz (o fila) representa una fila de datos en el CSV.
 * Cada elemento dentro de las sub-matrices se une con un delimitador 
 * (en este caso un punto y coma ';') y cada fila se une con un salto de línea.
 * 
 * @param {Array} data - Matriz bidimensional que contiene los datos.
 * @returns {string} - Cadena representando los datos en formato CSV.
 */
function arrayToCSV(data) {
  return data.map(row => row.join(";")).join("\n");
}

/**
 * Esta función crea un archivo en formato CSV a partir de una cadena 
 * y luego inicia una descarga en el navegador del usuario para ese archivo.
 * 
 * @param {string} csvData - Cadena que contiene los datos en formato CSV.
 * @param {string} filename - Nombre que se asignará al archivo descargado.
 */
function downloadCSV(csvData, filename) {
  const blob = new Blob([csvData], { type: "text/csv" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export default {
  name: "ReportesTrackingEntregas",
  data() {
    return {
      filtro: {
        fechaInicial: '',
        fechaFinal: '',
        bodega: [],
      },
      menuFechaInicio: false,
      menuFechaFinal: false,
      dialogoBodegas: false,
      listaDeBodegas: [],
      buscarCodigoBodega: '',
      buscarNombreBodega: '',
      pagina: 1,
      totalPaginas: 0,
      itemsPorPagina: 10,
      loading: false,
      singleSelect: true,
      headersBodegas: [
        { text: "CÓDIGO", value: 'codigoBodega', width: "14%", align: 'left', sortable: false },
        { text: 'NOMBRE', value: 'nombreBodega', width: "76%%", align: 'left', sortable: false },
      ],
      footerPropsTabla: {
        'items-per-page-options': [10, 20, 30, 40, 50],
        'items-per-page-text': 'Items por página:',
        'show-current-page': true,
        'show-first-last-page': true,
      },
      bodegasSeleccionadas: [],
      bodegaSeleccionada: [],
      mensajeError: '',
      alert: false,
      rangoFechas: '',
      inProgress: false,
      userRoles: {},
      roles: Role,
      fechaInicialCalendario: '',
      fechaFinalCalendario: '',
      rules: {
        agregarRules: [
          v => !!v || "Este campo es requerido.",
        ],
      },
    }
  },
  created() {
    this.userRoles = this.auth.roles;
    this.pageBodegas();
  },
  computed: {
    ...mapState(["auth", "enterprise"]),
    /**
     * Función computada que devuelve la fecha actual en formato ISO (YYYY-MM-DD)
     * para que en el v-date-picker no seleccione fechas posteriores a las del día actual. 
     */
    currentDate() {
        return new Date().toISOString().split('T')[0];
    },
    /** Función usada para deshabilitar o habilitar el botón para generar un reporte de acuerdo a las propiedades de los filtros. */
    disableReport() {
      return this.filtro.fechaInicial === '' || 
        this.filtro.fechaFinal === '' || 
        this.rangoFechas !== '' || 
        this.menuFechaInicio === true ||
        this.menuFechaFinal === true;
    },
  },
  watch: {  
    /**
     * Este watcher se activa cuando alguna propiedad del objeto `filtro` cambia.
     * Es "deep", por lo que observa cambios en propiedades anidadas dentro de `filtro`.
     * Al detectar cambios, se encarga de desactivar la alerta (`alert` se establece en false).
     */
    filtro: {
      deep: true,
      handler() {
        this.alert = false;
      }
    },
    /** Si hay cambios en la variable "pagina", se llama a la función pageBodegas() */
    pagina: function () {
      this.pageBodegas();
    },
    /** Si hay cambios en la variable "itemsPorPagina", se actualiza la variable "pagina" a 1 y se llama a la función pageBodegas() */
    itemsPorPagina: function () {
      this.pagina = 1;
      this.pageBodegas();
    },
    'buscarCodigoBodega': function() {
      this.pagina = 1;
      this.pageBodegas();
    },
    'buscarNombreBodega': function() {
      this.pagina = 1;
      this.pageBodegas();
    },
  },
  methods: {
    /**
     * Este método verifica que la fecha inicial (`fechaUno`) sea anterior a la fecha final (`fechaDos`). Además, 
     * se asegura de que el rango de fechas seleccionadas no exceda un mes. Si alguna de estas condiciones no se cumple, 
     * establece un mensaje de error correspondiente y marca el estado como inválido (`invalid = true`).
     */
    validacionesFechas() {
      const fechaUno = new Date(`${this.filtro.fechaInicial}T00:00:00-05:00`);
      const fechaDos = new Date(`${this.filtro.fechaFinal}T00:00:00-05:00`);

      if (fechaUno > fechaDos) {
        this.rangoFechas = 'La fecha final debe ser mayor a la fecha inicial';
        return;
      }

      let fechaMaximaPermitida = new Date(fechaUno);
      fechaMaximaPermitida.setMonth(fechaMaximaPermitida.getMonth() + 1);

      if (fechaUno.getDate() === 1) {
        fechaMaximaPermitida.setDate(0);
      }

      if (fechaDos > fechaMaximaPermitida) {
        this.rangoFechas = 'Las fechas seleccionadas no deben exceder un mes';
      } else {
        this.rangoFechas = '';
      }
    },
    /** Guarda la fecha inicial seleccionada en el calendario y realiza las validaciones de rango de fechas. */
    saveFechaInicial() {
      this.$refs.menuFechaInicio.save(this.fechaInicialCalendario);
      this.filtro.fechaInicial = this.fechaInicialCalendario;
      this.validacionesFechas();
    },
    /** Limpia la fecha inicial seleccionada y restablece los campos relacionados. */
    cleanFechaInicial() {
      this.fechaInicialCalendario = '';
      this.$refs.menuFechaInicio.save('');
      this.filtro.fechaInicial = '';
    },
    /** Guarda la fecha final seleccionada en el calendario y realiza las validaciones de rango de fechas. */
    saveFechaFinal() {
      this.$refs.menuFechaFinal.save(this.fechaFinalCalendario);
      this.filtro.fechaFinal = this.fechaFinalCalendario;
      this.validacionesFechas();
    },
    /** Limpia la fecha final seleccionada y restablece los campos relacionados. */
    cleanFechaFinal() {
      this.fechaFinalCalendario = '';
      this.$refs.menuFechaFinal.save('');
      this.filtro.fechaFinal = '';
    },
    /**
     * Verifica si una bodega está seleccionada.
     * 
     * @param {string} codigoBodega - El código de la bodega.
     * @returns {boolean} - Devuelve true si la bodega está seleccionada, de lo contrario, false.
     */
    isSelected(codigoBodega) {
      return this.bodegasSeleccionadas.includes(codigoBodega);
    },
    /**
     * Selecciona o deselecciona una bodega.
     * 
     * @param {Object} item - El objeto de la bodega que contiene el código de la bodega.
     */
    selectBodega(item) {
      const index = this.bodegasSeleccionadas.indexOf(item.codigoBodega);
      if (index > -1) {
        this.bodegasSeleccionadas.splice(index, 1);
      } else {
        this.bodegasSeleccionadas.push(item.codigoBodega);
      }
    },
    /**
     * Obtiene y pagina la lista de bodegas desde el backend.
     * Actualiza la lista de bodegas seleccionadas según los filtros aplicados.
     * 
     * @async
     */
    async pageBodegas() {
      this.listaDeBodegas = [];
      this.bodegaSeleccionada = [];
      try {
        const response = await this.$http.get(`msa-reports/api/bodegas/pageBodegas`, {
          params: {
            page: this.pagina - 1,
            size: this.itemsPorPagina,
            idEmpresa: this.enterprise.code,
            codigoBodega: this.buscarCodigoBodega,
            nombreBodega: this.buscarNombreBodega,
          }
        });
        this.listaDeBodegas = response.data.content;
        this.totalPaginas = response.data.totalElements;
        if (this.filtro.bodega && this.filtro.bodega.length > 0) {
          this.bodegaSeleccionada = this.listaDeBodegas.filter(item => this.filtro.bodega.includes(item.codigoBodega));
          this.bodegasSeleccionadas = this.filtro.bodega;
        } else {
          this.bodegaSeleccionada = this.listaDeBodegas.filter(item => this.bodegasSeleccionadas.includes(item.codigoBodega));
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * Elimina una bodega de las listas de bodegas seleccionadas.
     * 
     * @param {string} codigo - El código de la bodega a eliminar.
     */
    remove(codigo) {
      const indexSeleccionadas = this.bodegasSeleccionadas.indexOf(codigo);
      const indexSeleccionada = this.bodegaSeleccionada.findIndex(item => item.codigoBodega === codigo);
      
      if (indexSeleccionadas > -1) {
        this.bodegasSeleccionadas.splice(indexSeleccionadas, 1);
      }

      if (indexSeleccionada > -1) {
        this.bodegaSeleccionada.splice(indexSeleccionada, 1);
      }
    },
    /**
     * Confirma la selección de bodegas y actualiza el filtro.
     */
    confirmarBodegas() {
      this.filtro.bodega = this.bodegasSeleccionadas;
      this.cerrarDialogoBodegas();
    },
    /**
     * Borra todas las bodegas seleccionadas y cierra el diálogo de selección de bodegas.
     */
    borrarBodegas() {
      this.filtro.bodega = [];
      this.cerrarDialogoBodegas();
    },
    /**
     * Solicita y procesa los datos de los paquetes entregados basándose en los filtros.
     * Este método obtiene un conjunto de datos relacionados con los paquetes entregados 
     * por la transportadora. La respuesta esperada es un blob que contiene datos en formato CSV. Estos datos son luego 
     * procesados y convertidos a un archivo .csv para su descarga.
     */
    listarTrackingDomicilio() {
      this.inProgress = true;
      this.$http
      .get(`msa-reports/api/trackingDomicilio/lista`, {
        responseType: 'blob',
        params: {
          idEmpresa: this.enterprise.code,
          fechaInicial: this.filtro.fechaInicial,
          fechaFinal: this.filtro.fechaFinal,
          codigosBodegas: (this.filtro.bodega && this.filtro.bodega.length > 0) ? this.filtro.bodega.join(',') : '',
        }
      }).then(response => {
        const filename = `reporte_entregas_${this.filtro.fechaInicial.replace(/-/g, '_')}_al_${this.filtro.fechaFinal.replace(/-/g, '_')}.csv`;
        Papa.parse(response.data, {
          complete: (results) => {
            const csvData = arrayToCSV(results.data);
            if(results.data.length > 2) {
              downloadCSV(csvData, filename);
              this.clear();
              this.cerrarDialogoBodegas();
            } else {
              this.alert = true;
            }
          }
        });
        this.inProgress = false;
      }).catch(error => {
        console.log(error);
      })
    },
    /**
     * Limpia y reinicia el estado del formulario y sus validaciones.
     * Este método resetea las validaciones del formulario mediante el observer de VeeValidate.
     * Además, se encarga de limpiar las fechas seleccionadas, el tipo de transacción, el NIT de la transportadora y 
     * cualquier mensaje de error asociado a las fechas.
     */
    clear() {
      this.filtro.fechaFinal = '';
      this.filtro.fechaInicial = '';
      this.filtro.bodega = null;
      this.rangoFechas = '';
      this.alert = false;
    },
    /**
     * Cierra el diálogo de selección de bodegas y restablece los valores por defecto.
     * 
     * - Establece el estado del diálogo a falso.
     * - Limpia las listas de bodegas seleccionadas y la bodega actualmente seleccionada.
     * - Restablece los filtros de búsqueda y la configuración de paginación.
     * - Vuelve a cargar la lista de bodegas.
     */
    cerrarDialogoBodegas() {
      this.dialogoBodegas = false;
      this.bodegasSeleccionadas = [];
      this.bodegaSeleccionada = [];
      this.buscarCodigoBodega = '';
      this.buscarNombreBodega = '';
      this.pagina = 1;
      this.itemsPorPagina = 10;
      this.pageBodegas();
    }
  }
}
</script>
 <!-- #################################################################################### -->
 <!-- ###### Sección de Estilos                                                     ###### -->
 <!-- #################################################################################### -->
<style scoped>
.contenido {
  padding: 1rem;
  width: 100%;
  height: 86vh;
}
.descripcion {
  color: rgb(64, 62, 62); 
}
.no-resultados {
  font-size: 15px;
  margin: 0;
}
::v-deep .bodegas .theme--light.v-label {
  color: black !important;
  margin-left: 1rem !important;
}
::v-deep .bodegas .v-input--selection-controls__ripple {
  height: 0px !important;
  width: 0px !important;
}
::v-deep .bodegas .v-list-item--dense, .v-list--dense .v-list-item {
  min-height: 28px !important;
}

.filtrosBodega {
  display: flex;
}
::v-deep .estiloFilas div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}
::v-deep .estiloFilas div table thead tr th span {
  font-weight: bold;
  color: black !important;
}
.estiloFilas tbody tr td {
  font-size: 14px !important;
}
::v-deep .estiloFilas .v-data-footer {
  width: 100% !important;
}
::v-deep .estiloFilas .v-data-footer__select .v-select {
  margin: 5px;
  margin-left: 10px;
}
::v-deep .estiloFilas .v-data-table__wrapper {
  border: 1px solid #f7f6f6;
}
.rotate-animation {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>